import React from "react"
import { graphql } from "gatsby"
import { Styled, Flex, Box,} from "theme-ui"
import { Container } from '../components/item'
import styled from '@emotion/styled'

import SEO from "../components/seo"
import ContactForm from '../components/ContactForm'
import BgImage from '../components/BgImage'
import GiantCheck from '../images/icons/giant-check.svg'
// import { Paper } from "../components/style"
// import { Form, FormBlock } from "../blocks/form"
// import { Title, TitleBlock } from "../blocks/title"
// import { Image, ImageBlock } from "../blocks/image"
import {Content,  ContentWithImage, ContentBlock, ContentWithImageBlock } from "../blocks/content"
// import { PageLayout } from "../components/pageLayout"

import { useLocalJsonForm } from "gatsby-tinacms-json"
import Layout from "../components/layout"
import BasicHero from "../components/BasicHero"

const StyledGiantCheck = styled(GiantCheck)`
  height: 315px;
  width: 315px;
  margin-bottom: 80px;
`

export default function UseCase({ data }) {
  const [page] = useLocalJsonForm(data.use, UseForm)
  const blocks = page !== null && page.blocks ? page.blocks : []

  const seoTitle = page.title;
  const seoDescription = page.description;

  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
      />
      { page.hero !== null && page.hero.image !== null && 
        <BasicHero 
          fluid={page.hero.image.childImageSharp.fluid}  
          title={"Man Welding Hero Image"} 
          heroTitle={page.hero.headline}
          adjacentSectionColor={'white'}
        />
      }
      <Box className="CLASS"
        mt={6} 
        sx={{
          '& > section:first-of-type p:first-of-type': {
            fontSize: 2,
            lineHeight: '1.3',
          },
          '& p': {
            marginTop: 6
          }
        }}
        > 
      {blocks &&
        blocks.map(({ _template, ...data }, i) => {
          switch (_template) {

            case "ContentWithImageBlock":
              if (data.content && page.childrenUsesJsonBlockMarkdown[i])
                return (
                  <ContentWithImage
                    data={data}
                    html={
                      page.childrenUsesJsonBlockMarkdown[i]
                        .childMarkdownRemark.html
                    }
                  />
                )
              break
            case "ContentBlock":
              if (data.content && page.childrenUsesJsonBlockMarkdown[i])
                return (
                  <Content
                    html={
                      page.childrenUsesJsonBlockMarkdown[i]
                        .childMarkdownRemark.html
                    }
                  />
                )
              break
            default:
              return true
          }
          return true;
        })}
      </Box>
      

      <Box as="section">
        <Container py={[8,13]}>
          <Box sx={{margin: '0 auto', maxWidth: ['unset', '710px']}}>
            <Styled.h3>Contact Us</Styled.h3>
            <ContactForm name="Use Case Contact" minimal />
          </Box>
        </Container>

        <BgImage
          fluid={data.abstractTwo.childImageSharp.fluid}
          title="abstract design"
          height="auto"   
        >
          <Flex sx={{flexDirection: 'column', alignItems: 'center', maxWidth: '700px', margin: '0 auto', textAlign: 'center', paddingY: '100px'}}>
            <StyledGiantCheck />
            <Styled.h1 as="h4" sx={{variant: 'text.caps'}}>Value</Styled.h1>
            <Styled.h2 as="p" sx={{variant: 'text.body'}}>We guarantee outstanding products, competitive pricing, and 100% customer satisfaction.  </Styled.h2>
          </Flex>
        </BgImage>

      </Box>
    </Layout>
  )
}

const UseForm = {
  label: "Use Case",
  fields: [
    {
      label: "SEO Title",
      name: "rawJson.title",
      component: "text",
    },
    {
      label: "SEO Description",
      name: "rawJson.description",
      component: "textarea",
    },
    {
      label: "Hero",
      name: "rawJson.hero",
      component: "group",
      fields: [
        {
          label: "Headline",
          name: "headline",
          component: "text",
        },
        {
          label: "Textline",
          name: "textline",
          component: "text",
        },
        {
          label: "Image",
          name: "image",
          component: "image",
          parse: (filename) => `../images/${filename}`,
          uploadDir: () => `/content/images/`,
          previewSrc: (formValues) => {
            if (!formValues.hero || !formValues.hero.image)
              return ""
            return formValues.hero.image.childImageSharp.fluid.src
          },
        },
      ],
    },
    {
      label: "Page Sections",
      name: "rawJson.blocks",
      component: "blocks",
      templates: {
        ContentWithImageBlock,
        ContentBlock,
        // FormBlock,
        // ContentBlock,
      },
    },
  ],
}


export const useQuery = graphql`
  query($path: String!) {
    use: usesJson(path: { eq: $path }) {
      title
      description
      hero {
        headline
        image {
         childImageSharp {
           fluid(quality: 70, maxWidth: 1920) {
             ...GatsbyImageSharpFluid_withWebp
           }
         }
        }
      }
      blocks {
        _template
        content
        align
        background_color
        font_color
        image {
         childImageSharp {
           fluid(quality: 70, maxWidth: 1920) {
             ...GatsbyImageSharpFluid_withWebp
           }
         }
        }
      }

      childrenUsesJsonBlockMarkdown {
        childMarkdownRemark {
          html
        }
      }

      rawJson
      fileRelativePath
    }
    abstractTwo: file(relativePath: { eq: "abstract-background-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
